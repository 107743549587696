/*@font-face {
  font-family: 'Consolas';
  src: url('../font/consola.ttf');
}*/

html {
  color: #333333;
  background: #ffffff;
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: system-ui,-apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Hiragino Sans GB,Microsoft YaHei UI,Microsoft YaHei,Arial,sans-serif;
}
body {
  max-width: 50%;
  min-width: 1088px;
  padding: 0 8px;
  margin: 0 auto;
}
* {
  -webkit-tap-highlight-color: transparent;
}
ol,
ul {
  list-style: none;
}
p,
h1,
h2,
h3,
h4,
h5,
ul,
ol,
li,
blockquote {
  padding: 0;
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
figure{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0;
  display: block;
}
input,
select,
textarea {
  font-family: inherit;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
}
pre,
code {
  font-family: inherit;
}
input:-webkit-autofill {
  /* 自动填充的文本颜色 */
  -webkit-text-fill-color: #333333 !important;
  /* -webkit-text-fill-color: var(--text-color) !important; */

  /* 方式1：使用内阴影覆盖自动填充的背景色 */
  /* box-shadow: 0 0 0 1000px #ffffff inset !important; */

  /* 方式2：通过延迟一个月，取消自动填充的背景色 */
  -webkit-transition: background-color 2592000s ease-in-out 0s;
  transition: background-color 2592000s ease-in-out 0s;
}
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  /* background-color: transparent; */
}
::-webkit-scrollbar-thumb {
  /*border-radius: 2px;*/
  /* background-color: #999999; */
  background-color: rgba(0,0,0,0.1);
}
/*::-moz-selection {
  background-color: #4a4a4a;
  color: #f9f9f9;
}
::selection {
  background-color: #4a4a4a;
  color: #f9f9f9;
}
::-moz-selection {
  background-color: #4a4a4a;
  color: #f9f9f9;
}*/
.hover-underline {
  position: relative;
}
.hover-underline:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: #333333;
  visibility: hidden;
  transform: scaleX(0);
  transition: transform 200ms ease-in-out;
}
.hover-underline:hover:after {
  visibility: visible;
  transform: scaleX(1);
}
.header {
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 3px double #ccc; */
  user-select: none;  
  -webkit-user-select: none;
}
.header .home {
	display: inline-block;
    font-size: 16px;
    margin: 4px 0;
    white-space: nowrap;
}
.header .menu {
  margin-right: -9px;
  text-align: right;
}
.header .menu a {
  display: inline-block;
  font-size: 16px;
  margin: 4px 9px;
  white-space: nowrap;
}
.footer {
  text-align: center;
  font-size: 14px;
  padding: 12px 0;
  /* border-top: 3px double #ccc; */
  color: #999;
  user-select: none;
  -webkit-user-select: none;
}
.footer a:hover{
  text-decoration: underline;
}
#to-top {
  position: fixed;
  z-index: 99;
  right: 16px;
  bottom: 12px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 2px;
  background-color: #fff;
  opacity: 0.45;
  overflow: hidden;
  transform: translateY(66px);
  transition: transform 200ms ease-in-out;
}
#to-top.show {
  transform: translateY(0);
}
#to-top > span {
  display: block;
  width: 50%;
  height: 50%;
  position: absolute;
  left: 50%;
  top: 13px;
  transform: translateX(-50%) rotate(45deg);
}
#to-top > span:first-child {
  background-color: #000;
}
#to-top > span:last-child {
  background-color: #fff;
  transform: translate(-50%, 16%) rotate(45deg);
}
@media screen and (max-width: 560px) {
  .header .menu {
    margin-right: -2px;
    text-align: left;
  }
  .header .menu a {
    margin: 2px 5px;
    font-size: 15px;
  }
  .header .home{
    margin: 2px 0;
    font-size: 15px;  
  }
}
